import { library } from '@fortawesome/fontawesome-svg-core';
// Duotone
import {
  faSortUp as fadSortUp,
  faSortDown as fadSortDown,
  faSpinner as fadSpinner,
  faLock as fadLock,
  faRightLeft as fadRightLeft,
} from '@fortawesome/pro-duotone-svg-icons';
// Regular
import {
  faRedo as farRedo,
  faPlus as farPlus,
  faTrash as farTrash,
  faPencil as farPencil,
  faArrowLeft as farArrowLeft,
  faUpload as farUpload,
  faGraduationCap as farGraduationCap,
  faFileAlt as farFileAltlt,
  faLink as farLink,
  faChevronUp as farChevronUp,
  faChevronDown as farChevronDown,
  faChevronLeft as farChevronLeft,
  faChevronRight as farChevronRight,
  faSearch as farSearch,
  faTimes as farTimes,
  faExternalLink as farExternalLink,
  faHeartbeat as farHeartbeat,
  faMedkit as farMedkit,
  faIndustryAlt as farIndustryAlt,
  faIdCard as farIdCard,
  faArrowTrendUp as farArrowTrendUp,
  faCoins as farCoins,
  faBuildingColumns as farBuildingColumns,
  faPercent as farPercent,
  faBell as farBell,
  faCircleInfo as farCircleInfo,
  faExclamationTriangle as farExclamationTriangle,
  faBoltLightning as farBoltLightning,
  faDoorOpen as farDoorOpen,
  faCheck as farCheck,
  faXmark as farXmark,
  faCircleCheck as farCircleCheck,
  faCircleXmark as farCircleXmark,
  faCircleUser as farCircleUser,
  faUserXmark as farUserXmark,
  faUser as farUser,
  faCakeCandles as farCakeCandles,
  faEyeSlash as farEyeSlash,
  faEye as farEye,
  faRectangleVerticalHistory as farRectangleVerticalHistory,
  faGear as farGear,
  faUsersSlash as farUserSlash,
  faCodeCompare as farCodeCompare,
  faBriefcase as farBriefcase,
  faEnvelope as farEnvelope,
  faBuildingMemo as farBuildingMemo,
  faSitemap as farSitemap,
  faMoneyBill as farMoneyBill,
  faPersonFalling as farPersonFalling,
  faCalendarCheck as farCalendarCheck,
  faHouse as farHouse,
  faStar as farStar,
  faHome as farHome,
  faMobile as farMobile,
  faPhone as farPhone,
  faFax as farFax,
  faGlobe as farGlobe,
  faMobileNotch as farMobileNotch,
  faTag as farTag,
  faFileLock as farFileLock,
  faLock as farLock,
} from '@fortawesome/pro-regular-svg-icons';
// Solid
import {
  faSync as fasSync,
  faClipboard as fasClipboard,
  faClipboardCheck as fasClipboardCheck,
  faFileAlt as fasFileAlt,
  faLink as fasLink,
  faFloppyDisk as fasFloppyDisk,
  faRightToBracket as fasRightToBracket,
  faRotateRight as fasRotateRight,
  faEnvelope as fasEnvelope,
  faIdCard as fasIdCard,
  faShieldCheck as fasShieldCheck,
  faCalculator as fasCalculator,
  faPercent as fasPercent,
  faFileContract as fasFileContract,
  faPhone as fasPhone,
  faBallotCheck as fasBallotCheck,
  faArrowRightArrowLeft as fasArrowRightArrowLeft,
  faDownload as fasDownload,
  faPlay as fasPlay,
  faAt as fasAt,
  faFax as fasFax,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faUser as fasUser,
  faKey as fasKey,
  faInfo as fasInfo,
  faPencil as fasPencil,
  faTrash as fasTrash,
  faCircleMinus as fasCircleMinus,
  faGripDotsVertical as fasGripDotsVertical,
  faClock as fasClock,
  faMailboxFlagUp as fasMailboxFlagUp,
  faStar as fasStar,
  faQuoteRight as fasQuoteRight,
  faPaperPlaneTop as fasPaperPlaneTop,
  faFilePdf as fasFilePdf,
  faFileWord as fasFileWord,
  faFilePowerpoint as fasFilePowerpoint,
  faCalendarDay as fasCalendarDay,
  faArrowUpRightFromSquare as fasArrowUpRightFromSquare,
  faSigma as fasSigma,
  faBuilding as fasBuilding,
  faUndo as fasUndo,
  faPlug as fasPlug,
  faFlag as fasFlag,
  faPartyHorn as fasPartyHorn,
  faHandsHoldingHeart as fasHandsHoldingHeart,
  faFileXls as fasFileXls,
  faStarShooting as fasStarShooting,
  faHeadset as fasHeadset,
  faFileImport as fasFileImport,
  faCircleInfo as fasCircleInfo,
  faSparkles as fasSparkles,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  farRedo,
  farTrash,
  farPlus,
  farPencil,
  farArrowLeft,
  farUpload,
  farGraduationCap,
  farFileAltlt,
  farLink,
  farChevronUp,
  farChevronDown,
  farChevronLeft,
  farChevronRight,
  farSearch,
  farTimes,
  farExternalLink,
  farHeartbeat,
  farMedkit,
  farIndustryAlt,
  farIdCard,
  farArrowTrendUp,
  farCoins,
  farBuildingColumns,
  farPercent,
  farBell,
  farCircleInfo,
  farExclamationTriangle,
  farBoltLightning,
  farDoorOpen,
  farCheck,
  farXmark,
  farCircleCheck,
  farCircleXmark,
  farCircleUser,
  farUser,
  farCakeCandles,
  farEyeSlash,
  farEye,
  farUserXmark,
  farRectangleVerticalHistory,
  farGear,
  farCodeCompare,
  farBriefcase,
  farEnvelope,
  farBuildingMemo,
  farSitemap,
  farMoneyBill,
  farPersonFalling,
  farCalendarCheck,
  farUserSlash,
  farHouse,
  farUserSlash,
  farStar,
  farHome,
  farMobile,
  farPhone,
  farFax,
  farGlobe,
  farTag,
  farFileLock,
  farLock,
  fasPaperPlaneTop,
  farMobileNotch,
  fasSync,
  fasClipboard,
  fasClipboardCheck,
  fasFileAlt,
  fasLink,
  fasFloppyDisk,
  fasRightToBracket,
  fasEnvelope,
  fasRotateRight,
  fasIdCard,
  fasShieldCheck,
  fasCalculator,
  fasPercent,
  fasFileContract,
  fasPhone,
  fasBallotCheck,
  fasArrowRightArrowLeft,
  fasDownload,
  fasPlay,
  fasAt,
  fasFax,
  fasChevronLeft,
  fasChevronRight,
  fasUser,
  fasKey,
  fasInfo,
  fasPencil,
  fasTrash,
  fasCircleMinus,
  fasGripDotsVertical,
  fasClock,
  fasMailboxFlagUp,
  fasStar,
  fasQuoteRight,
  fasFilePdf,
  fasFileWord,
  fasFilePowerpoint,
  fasCalendarDay,
  fasArrowUpRightFromSquare,
  fasSigma,
  fasBuilding,
  fasUndo,
  fasPlug,
  fasFlag,
  fasPartyHorn,
  fasHandsHoldingHeart,
  fasFileXls,
  fasStarShooting,
  fasHeadset,
  fadSortUp,
  fadSortDown,
  fadSpinner,
  fadLock,
  fadRightLeft,
  fasFileImport,
  fasCircleInfo,
  fasSparkles,
);

export default FontAwesomeIcon;
